import React, { Component } from 'react';
import BrowseNavigation from '../components/browse/BrowseNavigation';
import PropTypes from 'prop-types';
import dataComponent from '../hoc/dataComponent';
import { compose } from 'redux';
import { loadInstruments } from '../actions/instrument';
import { loadGenres } from '../actions/genre';
import { loadPeriods } from '../actions/period';
import { defineMessages, injectIntl } from 'react-intl';
import metaInjector from '../hoc/metaInjector';
import CapacitorSkeletonIfLoading from '../components/capacitor/SkeletonIfLoading';

import stylesBrowse from './Browse.css';

const messages = defineMessages({
  metaTitle: {
    id: 'browse.meta.title',
    defaultMessage: 'Browse Classical Genres, Periods, Instruments on IDAGIO | IDAGIO',
  },
  metaDescription: {
    id: 'browse.meta.description',
    defaultMessage: 'Browse by composer, performer, instrument, genre or epoch and more!',
  },
  metaKeywords: {
    id: 'browse.meta.keywords',
    defaultMessage:
      'browse classical music, browse classical performers, classical instrumental music, mozart classical music, beethoven classical music',
  },
});

function getMetaInformation(props) {
  const { intl } = props;
  const { formatMessage } = intl;
  return {
    title: formatMessage(messages.metaTitle),
    description: formatMessage(messages.metaDescription),
    keywords: formatMessage(messages.metaKeywords),
  };
}

class Browse extends Component {
  static propTypes = {
    children: PropTypes.object.isRequired,
  };

  render() {
    return (
      <div className="u-page-container">
        <div className={stylesBrowse.view}>
          <h1 className="sr-only">Browse</h1>
          <BrowseNavigation />
          <CapacitorSkeletonIfLoading noInsets>{this.props.children}</CapacitorSkeletonIfLoading>
        </div>
      </div>
    );
  }
}

function fetchData(store) {
  // Even though the content of the page is coming from files in /data/* as
  // hardcoded values, we still load the backend data, to not show links to
  // browse data that might have been deleted on the backend and would result
  // in 404.
  return [
    store.dispatch(loadInstruments()),
    store.dispatch(loadGenres()),
    store.dispatch(loadPeriods()),
    __CAPACITOR__ && store.dispatch({ type: 'RESET_QUICK_SEARCH' }),
  ].filter(Boolean);
}

export default compose(
  injectIntl,
  dataComponent(fetchData),
  metaInjector(getMetaInformation)
)(Browse);
