const FONT_SIZES = [
  '--fs-xs',
  '--fs-s',
  '--fs-base',
  '--fs-m',
  '--fs-l',
  '--fs-xl',
  '--fs-xxl',
  '--fs-xxxl',
  '--fs-xxxxl',
  '--fs-xxxxxl',
];

const DEFAULT_MULTIPLIER = 1;
const MIN_MULTIPLIER = 0.5;
const MAX_MULTIPLIER = 2;

export function applyDynamicFontSize() {
  const urlParams = new URLSearchParams(window.location.search);
  const multiplierParam = urlParams.get('fs');
  if (!multiplierParam) {
    return;
  }

  const FONT_SIZES_IN_REM = {};
  const documentStyles = window.getComputedStyle(document.body);

  FONT_SIZES.forEach(fontSize => {
    FONT_SIZES_IN_REM[fontSize] = documentStyles
      .getPropertyValue(fontSize)
      .replace('rem', '')
      .trim();
  });

  let multiplier = parseFloat(multiplierParam);

  if (isNaN(multiplier)) {
    // eslint-disable-next-line
    console.warn(
      `Invalid font multiplier value: "${multiplierParam}". Using default: ${DEFAULT_MULTIPLIER}`
    );
    multiplier = DEFAULT_MULTIPLIER;
  } else {
    multiplier = Math.max(MIN_MULTIPLIER, Math.min(multiplier, MAX_MULTIPLIER));
  }

  Object.entries(FONT_SIZES_IN_REM).forEach(([key, size]) => {
    document.documentElement.style.setProperty(key, `${size * multiplier}rem`);
  });
}
