import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import React from 'react';
import ReactDom from 'react-dom';
import { Provider } from 'react-redux';
import {
  applyRouterMiddleware,
  browserHistory,
  Router,
  useRouterHistory,
  createRoutes,
  match,
} from 'react-router';
import Cookies from 'js-cookie';
import 'scrolling-element'; // polyfill for document.scrollingElement
import 'focus-visible'; // polyfill for :focus-visible pseudoclass

import { ANALYTICS_FIRST_WEB_VISIT, COOKIE_PREFERENCES_COOKIE } from '../constants';
import '../styles/styles.css';
import loadFonts from '../lib/fontloader';

import createMiddleware from '../store/middleware';
import { syncHistoryWithStore } from 'react-router-redux';
import fetchDataMiddleware from '../lib/routing/fetch-data-middleware';

import createStore from '../store/create';
import persistStore from '../store/persist/web';
import routes from '../routes';
import { saveCookiesConsent, setDeviceId } from '../actions/client';
import { reconstructLocalPlayerQueue } from '../actions/player';
import {
  selectClientIP,
  selectIsInApp,
  selectLocale,
  selectTicketPurchaseIsDisabled,
} from '../selectors/client';
import { getBaseNameFromLocale } from '../lib/locale-utils';
import { applyDynamicFontSize } from '../lib/font-size';

import { addLocaleData, IntlProvider } from 'react-intl';
import en from 'react-intl/locale-data/en';
import de from 'react-intl/locale-data/de';
import fr from 'react-intl/locale-data/fr';
import es from 'react-intl/locale-data/es';
import ko from 'react-intl/locale-data/ko';
import intlFormats from '../utils/intl-formats';

import generateToken from '../lib/generateToken';
import bootup, { fetchCurrentQueueData } from '../store/bootup';
import { selectUser } from '../selectors/user';

// Named Assets, unfortunately webpack won't work
// if I have a function for this "require"
require('file-loader?name=named-[hash]-[name].[ext]!../../static/favicon.svg');
require('file-loader?name=named-[hash]-[name].[ext]!../../static/favicon/mstile-144x144.png');
require('file-loader?name=named-[hash]-[name].[ext]!../../static/favicon/apple-touch-icon-57x57.png');
require('file-loader?name=named-[hash]-[name].[ext]!../../static/favicon/apple-touch-icon-72x72.png');
require('file-loader?name=named-[hash]-[name].[ext]!../../static/favicon/apple-touch-icon-114x114.png');
require('file-loader?name=named-[hash]-[name].[ext]!../../static/favicon/apple-touch-icon-120x120.png');
require('file-loader?name=named-[hash]-[name].[ext]!../../static/favicon/apple-touch-icon-144x144.png');
require('file-loader?name=named-[hash]-[name].[ext]!../../static/favicon/apple-touch-icon-152x152.png');

// This makes brings fonts into assets bundle for both web and electron
// (electron bundle contains web entry and its assets).
// The font paths are used to inline font-face declaration in html, see
// font-face.js
require.context('file-loader?name=fonts-[hash]-[name].[ext]!../assets/fonts/', false, /\.woff2?$/);

addLocaleData([...en, ...de, ...fr, ...es, ...ko]);

const routerMiddleware = applyRouterMiddleware(fetchDataMiddleware());
const messages = window.__locale_messages__;
const dest = document.getElementById('root');

function modifyRouterHistoryForInApp(store, routerHistory, locale) {
  const state = store.getState();
  if (selectIsInApp(state)) {
    routerHistory.push = urlString => {
      const url = new URL(urlString, location.origin);

      // Make sure inApp sticks
      url.searchParams.append('inApp', 1);
      url.searchParams.append('locale', locale);

      // Make sure disableTicketPurchase sticks
      if (selectTicketPurchaseIsDisabled(state)) {
        url.searchParams.append('disableTicketPurchase', 1);
      }

      // Why not `document.location = url` ?
      // Because that doesn't let iOS discern between user-initiated navigation
      // and redirects or iframes loading
      const link = document.createElement('a');
      link.href = url.toString();
      link.click();
    };
  }
}

async function initStore(store) {
  await persistStore(store);
  bootup(store);
}

function initDeviceId(store) {
  if (!Cookies.get('device-id-v2')) {
    const deviceId = generateToken(32);
    Cookies.set('device-id-v2', deviceId, {
      secure: process.env.ENV === 'production' && !process.env.NO_HTTPS,
      expires: 365 * 100,
    });
    store.dispatch(setDeviceId(deviceId));
    analytics.track(ANALYTICS_FIRST_WEB_VISIT);
  }
}

function initCookies(store) {
  const state = store.getState();
  const cookiePreferences = Cookies.get(COOKIE_PREFERENCES_COOKIE);
  if (cookiePreferences || selectIsInApp(state)) {
    const preferences = cookiePreferences
      ? JSON.parse(cookiePreferences)
      : // implicit consent for inApp
        {
          necessary: true,
          advertising: true,
          functional: true,
          marketingAndAnalytics: true,
        };
    store.dispatch(saveCookiesConsent(preferences));
  }
}

function hashLinkScroll() {
  const { hash } = window.location;

  if (hash !== '') {
    // Push onto callback queue so it runs after the DOM is updated,
    // this is required when navigating from a different page so that
    // the element is rendered on the page before trying to getElementById.
    setTimeout(() => {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) element.scrollIntoView();
    }, 0);
  }
}

function initializeSentry() {
  if (
    __CLIENT__ &&
    typeof window !== 'undefined' &&
    window.__sentry_dsn__ &&
    !window.__sentry_installed__
  ) {
    Sentry.setContext('app', {
      version: window.__app_version__,
      logger: 'Client',
    });
    Sentry.setTag('entry', 'web-client');

    const tracesSampleRate = window.__sentry_tracing_sampling_rate__ || 0.02;

    Sentry.init({
      dsn: window.__sentry_dsn__,
      release: window.__app_version__,
      environment: window.__env__,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV3Instrumentation(
            browserHistory,
            createRoutes(routes),
            match
          ),
        }),
      ],
      tracesSampleRate,
    });
    window.__sentry_installed__ = true;
  }
}

function setSentryUser(store) {
  const state = store.getState();
  const user = selectUser(state);
  const ip = selectClientIP(state);
  Sentry.setUser({
    id: user ? user.id : null,
    email: user ? user.email : null,
    ip_address: ip,
  });
}

async function main() {
  applyDynamicFontSize();

  initializeSentry();

  const store = createStore(window.__data__, createMiddleware(browserHistory));

  initDeviceId(store); // order is important (before initStore: connectPusher)
  initCookies(store);

  await initStore(store);

  setSentryUser(store);

  // Player reconstruction
  const queueDataFetched = await fetchCurrentQueueData(store);
  if (queueDataFetched) {
    store.dispatch(reconstructLocalPlayerQueue());
  }

  if (process.env.ENV !== 'production') {
    require('../lib/react-debugger').install();
  }

  const locale = selectLocale(store.getState());
  const history = syncHistoryWithStore(browserHistory, store);
  // useRouterHistory is not actually a hook
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const routerHistory = useRouterHistory(() => history)({
    basename: getBaseNameFromLocale(locale),
  });

  modifyRouterHistoryForInApp(store, routerHistory, locale);

  await loadFonts(store);

  const component = (
    <IntlProvider locale={locale} messages={messages} formats={intlFormats}>
      <Provider store={store} key="provider">
        <Router
          history={routerHistory}
          routes={routes}
          render={routerMiddleware}
          onUpdate={hashLinkScroll}
        />
      </Provider>
    </IntlProvider>
  );

  if (!window.Intl) {
    require.ensure(
      [
        'intl',
        'intl/locale-data/jsonp/en.js',
        'intl/locale-data/jsonp/de.js',
        'intl/locale-data/jsonp/fr.js',
        'intl/locale-data/jsonp/es.js',
        'intl/locale-data/jsonp/ko.js',
      ],
      require => {
        require('intl');
        require('intl/locale-data/jsonp/en.js');
        require('intl/locale-data/jsonp/de.js');
        require('intl/locale-data/jsonp/fr.js');
        require('intl/locale-data/jsonp/es.js');
        require('intl/locale-data/jsonp/ko.js');

        ReactDom.hydrate(component, dest);
      }
    );
  } else {
    ReactDom.hydrate(component, dest);
  }

  window.store = store;
}

main();
