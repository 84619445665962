import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import enhanceWithClickOutside from 'react-click-outside';

import classnames from 'classnames';
import AudioQuality from '../common/AudioQuality';

import IconLabel from './../util/IconLabel';
import { selectPlayerAudioQualityDialogIsOpen } from '../../selectors/client';
import { closePlayerAudioQualityDialog, openPlayerAudioQualityDialog } from '../../actions/ui';

import styles from './PlayerQuality.css';
import RadioButton from '../util/RadioButton';

class PlayerQuality extends PureComponent {
  static propTypes = {
    disabled: PropTypes.bool.isRequired,
    isOpen: PropTypes.bool.isRequired,

    open: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
  };

  state = {
    isOpen: false,
  };

  onClick = () => {
    if (this.props.isOpen) {
      this.props.close();
    } else {
      this.props.open();
    }
  };

  render() {
    const classNames = classnames(styles.quality, {
      [styles.isDisabled]: this.props.disabled,
    });

    const audioQualityClassNames = classnames(styles.menu, {
      [styles.isActive]: this.props.isOpen,
    });

    return (
      <div className={classNames}>
        <IconLabel
          name="settings"
          size="small"
          className={styles.settings}
          onClick={this.onClick}
        />
        <AudioQuality>
          {(currentQualityLevel, onQualityChange, supportedAudioQuality) => (
            <div className={audioQualityClassNames}>
              {supportedAudioQuality.map(
                ({ title, value, validValues = [value], allowed, disabled }) => {
                  return (
                    <RadioButton
                      key={value}
                      className="input-radio--dark"
                      checked={validValues.includes(currentQualityLevel)}
                      allowed={allowed}
                      disabled={disabled}
                      id={`player-quality-${value}`}
                      onChange={e => onQualityChange(e.target.value)}
                      name="player-quality"
                      value={value}
                      description={title}
                    />
                  );
                }
              )}
            </div>
          )}
        </AudioQuality>
      </div>
    );
  }

  handleClickOutside = () => {
    if (this.props.isOpen) {
      this.props.close();
    }
  };
}

function mapStateToProps(state) {
  return {
    isOpen: selectPlayerAudioQualityDialogIsOpen(state),
  };
}

export default connect(mapStateToProps, {
  open: openPlayerAudioQualityDialog,
  close: closePlayerAudioQualityDialog,
})(enhanceWithClickOutside(PlayerQuality));
