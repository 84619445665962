// @flow
import React from 'react';
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';
import type { IntlShape } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { Link } from 'react-router';

import { CONSTANT_MESSAGES } from '../../constants';
import IconLabel from '../util/IconLabel';
import styles from './ShareButton.css';
import * as uiActions from '../../actions/ui';
import CapacitorRipple from '../capacitor/Ripple';
import { selectUserIsAuthenticated } from '../../selectors/user';
import { thisMessages } from '../messages/BookletPreviewsModal';
import { selectLocale } from '../../selectors/client';
import { splitLocale } from '../../lib/locale-utils';

const messages = defineMessages({
  bookletButton: {
    id: 'booklet.button.text',
    defaultMessage: 'View Booklet',
  },
});

declare var __CAPACITOR__: boolean;
declare var __ELECTRON__: boolean;

type OwnProps = {
  bookletUrl: string,
  trackOpenBooklet: Function,
  hideText?: boolean,
  className?: string,
  imageUrl?: string,
  entityType: string,
};

type MapStateToProps = {
  isAuthenticated: boolean,
  language: string,
};

type DispatchProps = {
  showModal: Function,
};

type Props = OwnProps & MapStateToProps & DispatchProps & { intl: IntlShape };

const BookletButton = ({
  intl,
  bookletUrl,
  trackOpenBooklet,
  showModal,
  hideText = true,
  className,
  isAuthenticated,
  entityType,
  imageUrl,
  language,
}: Props) => {
  const text = intl.formatMessage(messages.bookletButton);
  const ariaButton = intl.formatMessage(CONSTANT_MESSAGES.externalLink, { linkText: text });

  const ButtonContent = () => (
    <React.Fragment>
      <IconLabel name="booklet" size={hideText ? 'default' : 'small'} />
      {!hideText && <span className={styles.text}>{text}</span>}
    </React.Fragment>
  );

  const onBookletClick = e => {
    if (!isAuthenticated) {
      showModal(
        'FREE_EXPERIENCE_PREVIEWS_MODAL',
        { trigger: 'booklet' },
        {
          imageUrl,
          trigger: 'bookletPreviewsModal',
          title: (
            <FormattedMessage
              id="booklet-previews-modal.header"
              defaultMessage="Want to see this booklet?"
            />
          ),
          subtitle: (
            <FormattedMessage
              id="booklet-previews-modal.subtitle"
              defaultMessage="Create a free account to read all about {resourceName}"
              values={{ resourceName: intl.formatMessage(thisMessages[entityType]) }}
            />
          ),
        }
      );
      return;
    }

    trackOpenBooklet(e);

    if (__ELECTRON__) {
      window.require('electron').ipcRenderer.send('view-booklet', bookletUrl, language);
      e.preventDefault();
      return;
    }

    showModal(
      'BOOKLET_MODAL',
      { trigger: entityType },
      {
        bookletUrl,
      }
    );
  };

  const classNames = classnames(styles.shareButton, styles.bookletButton, className);

  if (__CAPACITOR__) {
    return (
      <Link
        to={`/booklet?bookletUrl=${bookletUrl}`}
        onClick={trackOpenBooklet}
        className={classNames}
        title={text}
      >
        <CapacitorRipple />
        <ButtonContent />
      </Link>
    );
  }

  return (
    <button className={classNames} onClick={onBookletClick} aria-label={ariaButton} title={text}>
      <ButtonContent />
    </button>
  );
};

function mapStateToProps(state: Object): MapStateToProps {
  const locale = selectLocale(state);
  return {
    isAuthenticated: selectUserIsAuthenticated(state),
    language: splitLocale(locale).language,
  };
}

const dispatchProps: DispatchProps = {
  showModal: uiActions.showModal,
};

export default compose(connect(mapStateToProps, dispatchProps), injectIntl)(BookletButton);
