// @flow
import React from 'react';
import { connect } from 'react-redux';
import Image from '../util/Image';
import HeroSliderItemLink from './HeroSliderItemLink';
import { CircularPlayButton } from '../common/CircularPlayButton';
import { IMGIX_PARAMS } from '../../constants';
import styles from './HeroSliderItem.css';

import {
  selectPlayableItemPreviewIsLoadingToPlay,
  selectPlayableItemPreviewIsPlaying,
} from '../../selectors/playableItemPreview';

import * as playableItemPreviewActions from '../../actions/playableItemPreview';

type OwnProps = {
  item: Object,
  isActive: boolean,
  onClick: Function,
  beforeTogglePlay: Function,
  cancelAutoSlide: Function,
  onClick: Function,
  url: string,
  groupId: string,
};

type MapStateToProps = {
  isPlaying: boolean,
  isLoading: boolean,
};

type DispatchProps = {
  togglePlay: Function,
};

type Props = OwnProps & MapStateToProps & DispatchProps;

function PlayableHeroSliderItem({
  isActive,
  url,
  item,
  isPlaying,
  isLoading,
  togglePlay,
  onClick,
  beforeTogglePlay,
  cancelAutoSlide,
  groupId,
}: Props) {
  const { title, subtitle, imageUrl, imageCredits } = item;

  const onTogglePlay = playing => {
    beforeTogglePlay(item, playing, groupId);
    cancelAutoSlide();
    return togglePlay(item, Date.now());
  };
  return (
    <div className={styles.slideContent}>
      <figure className={styles.imageContainer} role="none">
        <HeroSliderItemLink onClick={onClick} isActive={isActive} url={url}>
          <Image
            noBase
            alt=""
            src={imageUrl}
            auto={IMGIX_PARAMS}
            className={styles.image}
            width={320}
            height={320}
            loadingMode="lazy"
          />
          {imageCredits && (
            <figcaption className={styles.credits}>Photo: {imageCredits}</figcaption>
          )}
        </HeroSliderItemLink>
        <div className={styles.play}>
          <CircularPlayButton
            onClick={onTogglePlay}
            tabIndex={isActive ? undefined : -1}
            playing={isPlaying}
            loading={isLoading}
            light
          />
        </div>
      </figure>
      <HeroSliderItemLink onClick={onClick} isActive={isActive} url={url}>
        <div className={styles.description}>
          <p className={styles.title}>{title}</p>
          <p className={styles.subtitle}>{subtitle}</p>
        </div>
      </HeroSliderItemLink>
    </div>
  );
}

function mapStateToProps(state: Object, ownProps: OwnProps): MapStateToProps {
  const item = ownProps.item;
  return {
    isPlaying: selectPlayableItemPreviewIsPlaying(state, item),
    isLoading: selectPlayableItemPreviewIsLoadingToPlay(state, item),
  };
}

const dispatchProps: DispatchProps = {
  togglePlay: playableItemPreviewActions.togglePlayPlayableItemPreview,
};

export default connect(mapStateToProps, dispatchProps)(PlayableHeroSliderItem);
